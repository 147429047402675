
.marketplace-card {
  padding: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s;
  overflow: hidden;

  &:hover {
    transform: scale(1.05);
    z-index: 1;
  }

  .recharts-wrapper {
    width: 100% !important;
    max-width: 100%;
    height: auto !important;
    overflow: hidden;
    margin: 0 auto;
  }

  .recharts-surface {
    width: 100% !important;
    height: auto !important;
  }

  .text-fs-body-md {
    word-wrap: break-word;
      overflow-wrap: break-word; 
      white-space: normal;       
      font-size: 0.9rem;         
      line-height: 1.2;          
    font-size: 0.9rem;           
  }
}

@media (max-width: 768px) {
  .marketplace-card {
    padding: 0.5rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    .text-fs-body-md {
      font-size: 0.8rem;
    }

    .recharts-wrapper {
      width: 100% !important;
      height: 150px !important;
    }
  }
}

@media (max-width: 576px) {
  .marketplace-card {
    padding: 0.5rem;

    .text-fs-body-md {
      font-size: 0.75rem;
    }

    .recharts-wrapper {
      height: 120px !important;
    }
  }
}

.marketplace-card-content {
  height: 110px;

  @media (max-width: 1200px) {
    height: 140px;
  }

  @media (max-width: 1000px) {
    height: 230px;
  }
  @media (max-width: 576px) {
    height: 150px;
  }
}
