@import '../../variables.scss';

.userHome-card {
  position: relative;
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: none;
  background: #fff;
  transform-origin: center;
  cursor: pointer;
}

.userHome-card:hover {
  transform: scale(1.05);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.card-text {
  background-color: $mercurx-primary;
  border-radius: 5px;
}

.card-body {
  padding: 20px;
  transition: all 0.4s ease-in-out;
}

.card-title {
  font-size: 1.8rem;
  color: #28666e;
  margin-bottom: 1rem;
}

.card-text {
  font-size: 1.2rem;
  color: #444;
}

.card img {
  max-width: 100%;
  transition: transform 0.4s ease-in-out;
}

.card:hover img {
  transform: scale(1.05);
}

@media screen and (max-width: 960px) {
  .userHome-card {
    transition: transform 0.4s ease-out;
  }
}

.articles {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
}

.home-page {
  background: #f5f5f5;
  padding: 36px 0;
}

.home-section {
  padding: 30px 0;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.section-title {
  font-size: 2.2rem;
  margin-bottom: 15px;
  color: #28666e;
  text-align: center;
  word-wrap: break-word;
}

@media screen and (max-width: 430px) {
  .section-title {
    font-size: 1.8rem !important;;
    line-height: 1.2;
    word-break: break-word;
  }
}


.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.custom-shadow {
  border-radius: 15px; 
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2), 
              0 4px 6px rgba(0, 0, 0, 0.1); 
  transition: transform 0.3s ease, box-shadow 0.3s ease; 
}

.custom-shadow-without-api {
  min-height: 120px;
  border-radius: 15px; 
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2), 
              0 4px 6px rgba(0, 0, 0, 0.1); 
  transition: transform 0.3s ease, box-shadow 0.3s ease; 
}

.fixed-table th, .fixed-table td {
  white-space: nowrap;
  background-color: gray;
}

.fixed-table .symbol-column {
  width: 20%;
}

.fixed-table .price-column {
  width: 30%;
}

.fixed-table .quantity-column {
  width: 30%;
}

.token-card {
  border: 2px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 300px;
  max-height: 400px;
}

.news-card {
  border: 2px solid #ddd; 
  border-radius: 12px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 300px;
  max-height: 300px;
  overflow-y: auto; 
}

.news-card ul {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
  text-align: left;
  list-style-type: none ;
  padding-left: 0px;
}

.news-card li {
  color: black;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden; 
}

.news-card li a {
  color: black;
  text-decoration: none;
  word-wrap: break-word; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap; 
  transition: color 0.3s ease;
}

.news-card li a:hover {
  color: #888; 
}

.news-card p {
  color: gray;
  font-style: italic;
  text-align: center;
}

.news-card ul li {
  padding: 10px 0;
}

.card-text .col-sm-3 {
  display: flex;
  align-items: center;
}