@import '../../variables.scss';

.mercurx-navbar {
  //background-color: rgba(255,255,255,0.65) !important;
}

.navbar-balance-text {
  max-width: 100px;
}

.banner-design {
  background: url('Banner.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 860px;
  width: 100%;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: inset 0px -50px 24px -25px rgba(255,255,255,1);
  -moz-box-shadow: inset 0px -50px 24px -25px rgba(255,255,255,1);
  box-shadow: inset 0px -50px 24px -25px rgba(255,255,255,1);

  .public-nav {
    color: $gray-400 !important;
  }
  .public-nav.disabled {
    color: $mercurx-dark-light !important;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-design {
    position: absolute;
    background-color: $gray-800;
    border-radius: 10px;
    //width: 85vw;
    right: 5vw;
    top: 55px;
    padding: 10px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }
  .navbar-design[data-ispublic="true"] {
    position: absolute;
    background-color: white;
    border-radius: 10px;
    //width: 85vw;
    right: 5vw;
    top: 55px;
    padding: 10px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }
}

.banner-design-launchpad {
  background: url('Banner3.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 600px;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: inset 0px -50px 24px -25px rgba(255,255,255,1);
  -moz-box-shadow: inset 0px -50px 24px -25px rgba(255,255,255,1);
  box-shadow: inset 0px -50px 24px -25px rgba(255,255,255,1);
  .public-nav {
    color: $gray-400 !important;
  }
  .public-nav.disabled {
    color: $mercurx-dark-light !important;
  }
}

.banner-design-profile {
  background: url('Banner3.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 600px;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: inset 0px -50px 24px -25px rgba(255,255,255,1);
  -moz-box-shadow: inset 0px -50px 24px -25px rgba(255,255,255,1);
  box-shadow: inset 0px -50px 24px -25px rgba(255,255,255,1);
  .public-nav {
    color: $gray-400 !important;
  }
  .public-nav.disabled {
    color: $mercurx-dark-light !important;
  }
}

.banner-public {
  .public-nav {
    color: $mercurx-primary !important;
  }
  .public-nav.disabled {
    color: $mercurx-dark-light !important;
  }
}

.navbar-wallet-account-container {
  width: 350px;
}
@media only screen and (max-width: 991px) {
  .banner-public {
    .public-nav {
      color: $mercurx-primary !important ;
    }
    .public-nav.disabled {
      color: $mercurx-dark-light !important;
    }
  }
}

.navbar-account-button {
  width: 250px;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgb(0 0 0 / 25%) !important;
}

.navbar-account-button:hover {
  width: 250px;
  background-color: $gray-300 !important;
  cursor: pointer;
}

.navbar-balance-button {
  width: 100px;
}

@media only screen and (max-width: 576px) {
  .navbar-balance-button {
    display: none !important;
  }
  .navbar-wallet-account-container {
    width: 250px !important;
  }
}

@media only screen and (max-width: 376px) {
  .navbar-balance-button {
    display: none !important;
  }
  .navbar-wallet-account-container {
    width: 200px !important;
  }
  .navbar-account-button {
    width: 200px;
  }
}

.navbar-popover-link {
  cursor: pointer;
  color: inherit;

 
}
.navbar-popover-link:hover {
  color: inherit;
  background-color: rgba(0, 0, 0, 0.10);
}
.about-popover-container {
  max-width: 750px;
}

.active-menu-item {
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: $mercurx-primary !important;
}

.active-submenu {
  color: $mercurx-primary !important;
}

.active-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
  color: $mercurx-primary !important;
}


@media (max-width: 768px) {
  .multisignals-logo {
    height: 40px; 
  }

}