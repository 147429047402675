// .chart-container {
//     display: flex;
//     justify-content: center;
//     margin-bottom: 20px;
//   }
  

.chart-header {
  display: flex;
  flex-direction: column; 
  align-items: flex-start; 
  padding: 10px; 
  margin-top: 10px;
}

.date-range-select {
  width: 100%; 
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-bottom: 15px;
}
.button-group-bordered {
  border: 1px solid #dcdcdc;  
  border-radius: 4px;
}
