.mybothistory-card {
  padding: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s;
  overflow: hidden;

  &:hover {
    transform: scale(1.05);
    z-index: 1;
  }

  .text-fs-body-md {
    font-size: 0.9rem;
    word-wrap: break-word;
  }

  .recharts-wrapper {
    width: 100% !important;
    max-width: 100%;
    height: auto !important;
    overflow: hidden;
    margin: 0 auto;
  }

  .recharts-surface {
    width: 100% !important;
    height: auto !important;
  }
}

.chart-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

/* Responsive Tasarım */
@media (max-width: 768px) {
  .mybothistory-card {
    padding: 0.5rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    .text-fs-body-md {
      font-size: 0.8rem; /* Küçük ekranlar için daha küçük font */
    }

    .recharts-wrapper {
      width: 100% !important;
      height: 150px !important; /* Grafik boyutunu küçült */
    }
  }

  .chart-container {
    flex-direction: column; /* Küçük ekranlarda grafik alt alta gelsin */
  }
}

@media (max-width: 576px) {
  .mybothistory-card {
    padding: 0.5rem;

    .text-fs-body-md {
      font-size: 0.75rem; /* Daha küçük ekranlar için daha küçük font */
    }

    .recharts-wrapper {
      height: 120px !important; /* Grafik daha da küçülsün */
    }
  }

  .chart-container {
    flex-direction: column;
    align-items: center;
  }
}
