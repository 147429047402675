@import '../../variables.scss';


.triple-column{
    height: 280px;
    background: #FAFAFA;
    box-shadow: 0px 2px 4px 1px rgba(138, 136, 136, 0.22);
    border-radius: 4.8px;
}
.checkbox-img{
    height: 70px;
    width: auto;
}