.completed-sales-div{
    //background: url("../../assets/img/logo/completed-sales-bg.png") no-repeat left top;
    min-height: 1190px;
    width: auto;
    background-size: contain;
    background-position-y: 30%;

}
.completed-sales-title-container{
    margin-top: 30px;
    margin-bottom: 20px;
}
.completed-sales-description > span{
    max-width:616px ;
    text-align: center;
}
