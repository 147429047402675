.bot-chart-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    .btn-group {
      flex-wrap: wrap;
    }
  
    .btn {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .form-group {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .pagination {
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .dropdown-toggle {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  