@import '../../variables.scss';

.tier-card{
    height: 320px !important;
}

@media only screen and (max-width: 1350px) {
    .tier-card {
        height: 450px !important;
    }
  
   
  }
  @media only screen and (max-width: 1045px) {
    .tier-card {
        height: 570px !important;
    }
  
 
  }
  @media only screen and (max-width: 992px) {
    .tier-card {
        height: 300px !important;
        margin-top: 10px !important;
    }
  }
  
  .standart{
    font-size: 'smaller' 
  }
