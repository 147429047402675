.main-layout-sidebar {
  width: 250px; 
}

.main-layout-navbar {
  width: calc(100% - 250px);
}
.main-layout-content-inner {
  overflow: hidden;
  padding: 1rem;
  box-sizing: border-box;
  height: 100%;
}
