@import '../../variables.scss';

.public-signup-layout-image {
  background: url('../../assets/img/background/green-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 30vw;
  height: 575px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1) !important;
  border-radius: 68px !important;
}
.public-signup-layout-col {
  height: 575px;
  box-shadow: 4px 4px 4px rgba(176, 176, 176, 0.25) !important;
  border-radius: 68px !important;
}
.public-signup-layout-form {
  width: 37vw;
}

@media only screen and (max-width: 1200px) {
  .public-signup-layout-image {
    width: 35vw;
  }

  .public-signup-layout-form {
    width: 42vw;
  }
}

@media only screen and (max-width: 992px) {
  .public-signup-layout-image {
    width: 40vw;
  }

  .public-signup-layout-form {
    width: 47vw;
  }
}

@media only screen and (max-width: 768px) {
  .public-signup-layout-form {
    width: 90vw;
  }
}

