.public-layout {
  width: 100vw;
  background-color: transparent;
  height: 85vh;
  min-height: 100vh;
}

.public-layout .content {
  flex: 1;
}
