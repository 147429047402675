@import "../../variables.scss";



@media only screen and (max-width: 768px) {
  .checkbox-profile-detail-row{
    margin-top: 10px !important;
  }
}

.checkbox-profile-detail-row{
    margin-top: 50px !important;
  
  .checkbox-row{
    display: flex;
    flex-direction: row;
    height: 250px;
  
  }

  @media only screen and (min-width: 576px) {
    .checkbox-row-content{
      max-width: 130px;
      min-width: 110px;
    }
  }

  
  @media only screen and (max-width: 576px) {
    .checkbox-row{
      display: flex;
      flex-direction: column;
      height: 200px;
      margin-bottom: 50px;
    }
  
  }
  
    
  .checkbox-title{
    color: $mercurx-t-head-color;
    margin-top: 15px;
  
  }
  
  .checkbox-date{
    color: $mercurx-t-body-color;
    margin-top: 10px;
  }
  
  .checkbox-line{
    color:  $mercurx-t-head-color;
    width: 100%;
  }

  }

  
