.mercurx-loading-full-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: rgba(0,0,0,0.25);
    z-index: 3000;
    overflow: hidden;
}