@import '../../variables.scss';

.tier-card{
    height: 320px !important;
    margin-bottom: 20px;
}
.row {
  display: flex;
  flex-wrap: wrap;
}

.row .tier-card:nth-child(3) {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  width: 50%;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 1350px) {
    .tier-card {
        height: 450px !important;
    }
  
   
  }
  @media only screen and (max-width: 1045px) {
    .tier-card {
        height: 570px !important;
    }
  
 
  }
  @media only screen and (max-width: 992px) {
    .tier-card {
        height: 350px !important;
        margin-top: 10px !important;
    }
  }
  
  .standart{
    font-size: 'smaller' 
  }
