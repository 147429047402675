$primary-color: #ffffff;
$secondary-color: #097875; 
$text-color: #030303; 
$highlight-color: $secondary-color; 

.home-page {
  .section-title {
    font-size: 2.5rem;
    color: $highlight-color;
    margin-bottom: 1rem;
  }

  .section-text {
    font-size: 1.1rem;
    color: $text-color;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }

  .home-section {
    margin: 2rem 2rem;
    padding: 3rem 0;  
    background-color: $primary-color;
    text-align: center;
    color: $text-color;
    display: flex;
    align-items: center;
    // border-radius: 15px; 
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2), 
                0 4px 6px rgba(0, 0, 0, 0.1); 
    transition: transform 0.3s ease, box-shadow 0.3s ease;


    .section-image {
      width: 100%;
      max-width: 500px;
      height: 250px;
      margin: 0 auto;
      border-radius: 15px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
      transition: transform 0.3s ease, box-shadow 0.3s ease;
    
      &:hover {
        transform: scale(1.05); 
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .flex-row-reverse {
    flex-direction: row-reverse;
  }

  .btn-highlight {
    background-color: $highlight-color;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($highlight-color, 10%);
      cursor: pointer;
    }
  }
}
