@import '../../variables.scss';

.bot-history-table {
  border-collapse: separate;
  border-spacing: 0 1em;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;

  tbody {
    tr {
      background: #fafafa;
      box-shadow: 0px 2px 4px 1px rgba(138, 136, 136, 0.22);
      border-radius: 4.8px;
      td {
        height: 80px;
        text-align: center;
        padding-top: 2%;
      }
    }
  }
}

.chart-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.order-id-link{
  cursor: pointer;
  color: $mercurx-primary  !important;
  text-decoration: underline ;
}
