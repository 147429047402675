@import "../../variables.scss";
.sales-projects-container {
    min-height: 50vh;
}
.sales-banner {
    min-height: 200px;
}
.sales-quick-handler {
    min-height: 100px;
}

.sales-table-button{
    .dropdown-toggle{
        background-color: $mercurx-light;
        color: $mercurx-t-body-color;
        border-color: #FAFAFA;
        border: 0px;

    }
    .dropdown-toggle:hover{
        background-color: $mercurx-primary;
        color: $mercurx-light;
        border-color: $mercurx-primary;
        border: 0px;
    }
}
